import React, { useState } from 'react';
import axios from 'axios';
import './LandingPage.css'; 
import Banner from './Banner';

function LandingPage() {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/api/subscribe`, { email });
            setMessage("Houston, we have liftoff! Your PRDPilot journey begins now.");
        } catch (error) {
            setMessage('An error occurred. Please try again.');
        }
    };

    return (
        <div className="landing-page">
            <Banner />
            <main className="landing-content">
                <header className="landing-header">
                    <h1><span className="highlight-prd">PRD</span>Pilot, Where Every PM Becomes a Visionary</h1>
                    <p className="tagline">Your AI-powered product sidekick, turning coffee-fueled ideas into polished product docs faster than you can say 'sprint planning'</p>
                </header>
                <div className="subscribe-section">
                    <p className="subscribe-message">Stay in the loop! Get exclusive updates on PRDPilot's development, launch, and future features.</p>
                    <form onSubmit={handleSubmit} className="subscribe-form">
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Enter your email"
                            required
                        />
                        <button type="submit">Subscribe</button>
                    </form>
                    {message && <p className={message.includes("Houston") ? "success-message" : "error-message"}>{message}</p>}
                </div>
                <section className="features">
                    <h2>What PRDPilot Can Do:</h2>
                    <ul>
                        <li>Create comprehensive PRDs</li>
                        <li>Generate acceptance criteria</li>
                        <li>Develop product vision and strategy</li>
                        <li>Set and track OKRs</li>
                        <li>Design product roadmaps</li>
                        <li>Craft one-pagers</li>
                        <li>Provide product advice</li>
                    </ul>
                </section>
            </main>
            <footer className="site-footer">
                <p>&copy; {new Date().getFullYear()} PRDPilot. All rights reserved.</p>
            </footer>
        </div>
    );
}

export default LandingPage;